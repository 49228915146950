import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

export const TitleReporte = () => {
    const handlechange = (opt) => {
        let value = opt.value.value;
        // if (value.includes('epensa'))
        //   value = ''
        console.log('value: ', value);
        localStorage.setItem('cuenta', value)
        window.location.reload();
    }

    const location = useLocation();
    const crumbs = location.pathname;
    let title = "";
    let flagCruda = false;
    let showCuenta = localStorage.getItem('cuenta');
    switch (crumbs) {
        case '/app/dashboard/ventas':
            title = "Desempeño de ventas";
            localStorage.setItem('ruta', '/app/dashboard/ventas');
            break;
        case '/app/dashboard/inventory':
            title = "Disponibilidad de inventarios";
            localStorage.setItem('ruta', '/app/dashboard/inventory');
            break;
        case '/app/dashboard/venta-cero':
            title = "Venta Cero";
            localStorage.setItem('ruta', '/app/dashboard/venta-cero');
            break;
        case '/app/dashboard/promotoria':
            title = "Desempeño de promotoría";
            localStorage.setItem('ruta', '/app/dashboard/promotoria');
            break;
        case '/app/dashboard/rutas':
            title = "Ventas por rutas";
            localStorage.setItem('ruta', '/app/dashboard/rutas');
            break;
        case '/app/dashboard/salud-inventarios':
            title = "Salud de Inventarios";
            localStorage.setItem('ruta', '/app/dashboard/salud-inventarios');
            break;
        case '/app/dashboard/disponibilidad-inventarios':
            title = "Disponibilidad de Inventarios";
            localStorage.setItem('ruta', '/app/dashboard/disponibilidad-inventarios');
            break;
        case '/app/dashboard/scorecard-VSPT':
            title = "Scorecard Sell Out";
            localStorage.setItem('ruta', '/app/dashboard/scorecard-VSPT');
            break;
        case '/app/dashboard/general':
            // title = "Reporte General";
            title = "Desempeño de ventas";
            localStorage.setItem('ruta', '/app/dashboard/general');
            break;
        case '/app/reportes/base-cruda':
            title = "Base Cruda";
            localStorage.setItem('ruta', '/app/reportes/base-cruda');
            flagCruda = true;
            if (showCuenta === 'Bepensa-Spirits') {
                localStorage.setItem('cuenta', 'BIB');
                window.location.reload();
            }
            break;
        case '/app/reportes/estatus-cadenas':
            title = "Estatus Cadenas";
            localStorage.setItem('ruta', '/app/reportes/estatus-cadenas');
            break;
        case '/app/portales':
            title = "Portáles";
            localStorage.setItem('ruta', '/app/reportes/estatus-cadenas');
            break;
        case '/app/reportes/tablero-comercial':
            title = "Tablero comercial";
            localStorage.setItem('ruta', '/app/reportes/tablero-comercial');
            break;
        case '/app/reportes/analisis-cobertura':
            title = "Desempeño geográfico";
            localStorage.setItem('ruta', '/app/reportes/analisis-cobertura');
            break;
        case '/app/ejecuciones':
            title = "Ejecuciones";
            localStorage.setItem('ruta', '/app/ejecuciones');
            flagCruda = true;
            if (showCuenta === 'Bepensa-Spirits') {
                localStorage.setItem('cuenta', 'BIB');
                window.location.reload();
            }
            break;
        default:
            const defTitleParts = crumbs.split('/');
            title = `testing-${defTitleParts[defTitleParts.length - 1]}`;
            break;
    }

    let ruta = localStorage.getItem('ruta') || '';
    let promotoriaRutas = 0;
    let flagEstatusCadenas = 0;
    if (ruta == '/app/dashboard/promotoria' || ruta == '/app/dashboard/rutas') {
        if (showCuenta != 'Demo') {
            showCuenta = 'Bepensa-Spirits';
            promotoriaRutas = 1;
        }
    }
    //if (ruta == '/app/reportes/estatus-cadenas'){flagEstatusCadenas=1;}

    return (
        <>
            <h5 className='mt-2 text-white fw-light'>{title}</h5>
            {promotoriaRutas == 0 ?
                ['BIB', 'Bepensa-Spirits', 'Madrilena'].find(found => found === localStorage.getItem('cuenta')) &&
                <>
                    <Select
                        className="ms-5 basic-select text-primary-emphasis"
                        name={"canal"}
                        classNamePrefix={"select"}
                        onChange={(selectedFilter) => {
                            const fixedFilter = {
                                name: 'canal',
                                value: selectedFilter
                            };
                            handlechange(fixedFilter);
                        }}
                        placeholder={'Cuenta'}
                        options={
                            !flagCruda ?
                                [
                                    {
                                        label: 'BIB',
                                        value: 'BIB'
                                    }, {
                                        label: 'Madrileña',
                                        value: 'Madrilena'
                                    },
                                    {
                                        label: 'Bepensa-Spirits',
                                        value: 'Bepensa-Spirits'
                                    }
                                ] :
                                [
                                    {
                                        label: 'BIB',
                                        value: 'BIB'
                                    }, {
                                        label: 'Madrileña',
                                        value: 'Madrilena'
                                    }
                                ]
                        }
                        closeMenuOnSelect={true}
                        maxMenuHeight={200}
                    />
                    <b className='ms-3'>{showCuenta}</b>
                </>
                : <><b className='ms-5'>{showCuenta}</b></>
            }
        </>
    );

};