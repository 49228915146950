import React, { useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import BTable from 'react-bootstrap/Table';
import { Col, Row, Pagination, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GlobalFilter } from '../../views/panels/project-crm/GlobalFilter';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FaFileExcel, FaRegPlusSquare } from "react-icons/fa";
import { FaChevronRight, FaArrowUp, FaArrowDown, FaCircle } from "react-icons/fa";
import { BsPlusCircleDotted, BsChevronRight } from "react-icons/bs";

export const TableCustom = ({ columns, data, classes, gradiet, isAmount, idTable, handleDescargaTabla, clickRow, columnClick, tableSort, setTableSort, setHandleFiltro,
    showEntries = false, showSearch = false, showPaginado = false, showFooter, columnGradient, columnAmount, columnAmount2, columnGradient2, left = false, right = false, cantEntries, showPlus,
    columnGradient3, columnGradient4, columnGradient5, columnGradient6, columnGradient7, columnGradient8, columnGradient9, columnGradient10, columnRank = 0,
    defaultColumnSort, defaultColumnSortDesc, isSorteable, customStyles, totalRowData
}) => {

    const [isVisible, setIsVisible] = useState(false);

    const searchDataDrill = (e, cell, column) => {
        if (e.detail === 1) {
            console.log(cell.value);
            setHandleFiltro(cell.value);
        }
    }

    let style;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        globalFilter,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: cantEntries ? cantEntries : 5,
                sortBy: [
                    {
                        id: defaultColumnSort,
                        desc: defaultColumnSortDesc
                    }
                ]
            },
            disableSortRemove: true,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const clickHandler = (e, toggleProps) => {
        toggleProps.onClick(e);
        const currentKey = Object.keys(tableSort || {})[0];
        const newKey = toggleProps.key.split('_')[1];
        const currentType = currentKey === newKey ? tableSort[currentKey] : null;
        let newType;
        switch (currentType) {
            case -1:
                newType = 1;
                break;
            case 1:
                newType = -1;
                break;
            default:
                newType = 1;
        }
        let newSort = {};
        newSort[newKey] = newType;
        if (setTableSort) setTableSort(newSort);
    };

    return (
        <>
            <Row className="mb-1">
                <Col xs={8} className="d-flex align-items-center">
                    {showEntries ?
                        <div>
                            Mostrar
                            <select
                                className="form-control w-auto mx-1"
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[5, 10, 20, 30, 40, 50, 'Todo'].map((pageSize, indexO) => (
                                    <option key={`complete-table-option-${indexO}`} value={pageSize === 'Todo' ? data?.length : pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                            registros
                        </div>
                        : <></>}

                    {
                        left ?
                            <Col xs={1} className="d-flex align-items-center" style={{ padding: 'none', marginBottom: '0.5rem' }}>
                                <div class="tooltipcustom">
                                    <span class="tooltiptextcustom">Descargar</span>
                                    <Button
                                        variant="info"
                                        onClick={handleDescargaTabla}
                                        color="success"
                                        className='text-white'
                                    >
                                        <FaFileExcel ></FaFileExcel>
                                    </Button>
                                </div>
                            </Col>
                            : <></>
                    }
                </Col>
                {
                    right ?
                        <Col xs={1} className="d-flex align-items-center" style={{ padding: 'none', marginBottom: '0.5rem' }}>
                            <div class="tooltipcustom">
                                <span class="tooltiptextcustom">Descargar</span>
                                <Button
                                    variant="info"
                                    onClick={handleDescargaTabla}
                                    color="success"
                                    className='text-white'
                                >
                                    <FaFileExcel ></FaFileExcel>
                                </Button>
                            </div>
                        </Col>
                        : <></>
                }
                <Col xs={3} className="d-flex justify-content-end">

                    {showSearch ?
                        <div>
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </div> : <></>
                    }
                </Col>
            </Row>
            <table className={classes} {...getTableProps()} style={customStyles ? { ...customStyles.font, width: '100%' } : { width: '100%' }} id={idTable} >
                <thead>
                    {headerGroups.map((headerGroup, indexG) => (
                        <tr key={`complete-table-group-${indexG}`} {...headerGroup.getHeaderGroupProps()}
                            style={customStyles ? { ...customStyles.header } : {}}>

                            {headerGroup.headers.map((column, indexCol) => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th key={`complete-tablecolumn-${indexCol}`} {...column.getHeaderProps(column.getSortByToggleProps())}
                                    onClick={(event) => {
                                        if (isSorteable)
                                            clickHandler(event, column.getHeaderProps(column.getSortByToggleProps()));
                                    }}
                                    title={column.title}
                                >
                                    {column.render('Header')}

                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <span className="feather icon-arrow-down text-muted float-right" />
                                            ) : (
                                                <span className="feather icon-arrow-up text-muted float-right" />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>

                    {page.map((row, i) => {
                        prepareRow(row);
                        let cont = 0;
                        let styleTd = '';
                        return (
                            <tr className='custom-tr' key={`complete-table-${i}`} style={i + 1 === page.length && customStyles ? totalRowData ? { ...customStyles.footer } : { ...customStyles.border, width: `${100 / columns.length}%` } : {}}>

                                {row.cells.map((cell, j) => {
                                    let formatted = 0;
                                    let aplicaStilo = 0;
                                    styleTd = '';
                                    cont++;
                                    if (gradiet === true) {
                                        if (cont === columnGradient || cont === columnGradient2 || cont === columnGradient3 || cont === columnGradient4
                                            || cont === columnGradient5 || cont === columnGradient6 || cont === columnGradient7 || cont === columnGradient8
                                            || cont === columnGradient9 || cont === columnGradient10) {
                                            formatted = parseFloat(cell.value.replace('%', ''));
                                            styleTd = 'text-end';
                                            aplicaStilo = 1;
                                        }
                                    }
                                    if ((cont === columnAmount || cont === columnAmount2) && isAmount) {
                                        return <td key={`complete-table-field-${j}`} className={styleTd}
                                            style={customStyles && i + 1 !== page.length && customStyles ? { ...customStyles.border, width: `${100 / columns.length}%` } : {}}>
                                            <Row key={`complete-table-field-2-${j}`}>
                                                <Col xs={1} style={{ marginRight: 'unset', marginLeft: 'unset', paddingLeft: 'unset' }}>$</Col>
                                                <Col xs={10} style={{ marginRight: 'unset', marginLeft: 'unset', paddingLeft: 'unset' }}>
                                                    {cell.render('Cell')}
                                                </Col>
                                            </Row>
                                            {(gradiet === true && formatted > 0 && aplicaStilo === 1) ? <FaArrowUp style={{ color: '#4caf50', marginLeft: '7px' }} /> : <></>}
                                            {(gradiet === true && formatted < 0 && aplicaStilo === 1) ? <FaArrowDown style={{ color: '#e53935', marginLeft: '7px' }} /> : <></>}
                                            {(gradiet === true && formatted === 0 && aplicaStilo === 1) ? <FaCircle style={{ color: '#ffeb3b', marginLeft: '7px' }} /> : <></>}
                                        </td>;
                                    } else {
                                        return <td key={`complete-table-field-3-${j}`}
                                            className={clickRow === true && cont === columnClick ? 'cursor-pointer ' + styleTd : styleTd}
                                            onClick={clickRow === true && cont === columnClick ? (e) => searchDataDrill(e, cell, cell.column) : () => { }}
                                            style={(customStyles && i + 1 !== page.length || !totalRowData) && customStyles ? { ...customStyles.border, width: `${100 / columns.length}%` } : {}}>
                                            {showPlus && cont === 1 ? <BsChevronRight className='me-2' /> : ''}
                                            {aplicaStilo === 1 ?
                                                <Row >
                                                    <Col xs={12} style={{ textAlign: 'center' }}>
                                                        {cell.render('Cell')}
                                                        {(gradiet === true && formatted > 0 && aplicaStilo === 1) ? <FaArrowUp style={{ color: '#4caf50', marginLeft: '7px' }} /> : <></>}
                                                        {(gradiet === true && formatted < 0 && aplicaStilo === 1) ? <FaArrowDown style={{ color: '#e53935', marginLeft: '7px' }} /> : <></>}
                                                        {(gradiet === true && formatted === 0 && aplicaStilo === 1) ? <FaCircle style={{ color: '#ffeb3b', marginLeft: '7px' }} /> : <></>}
                                                    </Col>
                                                </Row>
                                                :
                                                cell.render('Cell')
                                            }
                                        </td>;
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                {showFooter ?
                    <tfoot>
                        {footerGroups.map((footerGroup, indexG) => (
                            <tr key={`complete-table-group-${indexG}`} {...footerGroup.getFooterGroupProps()}>
                                {footerGroup.headers.map((column, indexCol) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th key={`complete-tablecolumn-${indexCol}`} {...column.getFooterProps(column.getSortByToggleProps())}
                                        onClick={(event) => {
                                            clickHandler(event, column.getFooterProps(column.getSortByToggleProps()));
                                        }}
                                    >
                                        {column.render('Footer')}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <span className="feather icon-arrow-down text-muted float-right" />
                                                ) : (
                                                    <span className="feather icon-arrow-up text-muted float-right" />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                    : <></>
                }
            </table>
            <br />
            {showPaginado ?
                <Row className="justify-content-between">
                    <Col>
                        <span className="d-flex align-items-center fz12">
                            Página{' '}
                            <strong>
                                {' '}
                                {pageIndex + 1} de {pageOptions.length}{' '}
                            </strong>{' '}
                            | Ir a la página:{' '}
                            <input
                                type="number"
                                min="0"
                                className="form-control ml-2"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>
                    </Col>
                    <Col>
                        <Pagination className="justify-content-end">
                            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                            <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                            <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                        </Pagination>
                    </Col>
                </Row> : <></>
            }

        </>
    );
}